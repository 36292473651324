<template>
  <div class="app-page">
    <Navbar />
    <div class="main-wrapper">
      <LeftMenu />
      <div class="container-fluid">
        <div class="row justify-content-end" v-if="isAdmin">
          <div class="Add">
            <button class="btn button" @click="createSubscriptionsPage">
              Add Subscription
            </button>
          </div>
        </div>
        <div
            v-if="showLoader == 1"
            class="lds-dual-ring-registry"
            id="loading"
          ></div>

        <div class="row" v-if="subscriptionPlans.length > 0">
          <div class="col-md-6 col-lg-4 p-2 pricing card-deck flex-column flex-md-row" v-for="plan in subscriptionPlans"
            :key="plan.id">
            <div class="card card-pricing text-center px-3 mb-4">
              <span class="h6 w-60 mx-auto px-4 py-1 rounded-bottom darkBGZincColor text-white shadow-sm">{{ plan.name
                }}</span>
              <div class="bg-transparent card-header pt-4 border-0">
                <h1 class="h1 font-weight-normal darkZincColor text-center mb-0" data-pricing-value="15">$<span
                    class="price">{{ plan.amount }}</span><span class="h6 text-muted ml-2">/ per month</span></h1>
              </div>
              <div class="card-body pt-0">
                <ul class="list-unstyled mb-4">
                  <li>{{ plan.description }}</li>
                  <li>Transactions: {{ plan.transactions }}</li>
                  <li>Per Transaction: ${{ plan.perTransaction }}</li>
                  <li>Free cancelation</li>
                </ul>
                <div>
                  <PaymentModal v-if="showAddPaymentModal" />
                  <button type="button" @click="payment(plan)" class="btn button custom-btn mb-3" v-if="User">Add
                    Subscription</button>
                  <button type="button" @click="deleteSubscription(plan.id)" class="btn button custom-btn mb-3"
                    v-if="isAdmin">Remove Subscription</button>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div v-if="showLoader == 0 && subscriptionPlans.length == 0" class="w-100 d-flex justify-content-center align-items-center">
          <p>There is no subscription added yet.</p>
        </div>

      </div>

    </div>

  </div>

</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import LeftMenu from "../components/leftmenu/LeftMenu.vue";
import Navbar from "../components/header/Navbar.vue";
import PaymentModal from "../components/payment/PaymentModal.vue";

export default {
  name: "Subscriptions",

  components: {
    Navbar,
    LeftMenu,
    PaymentModal,
  },

  data: function () {
    return {
      showForm: false,
      showAddPaymentModal: true,
      subscriptionPlans: [],
      User: false,
      isAdmin: false,
      showCardDetailModal: false,
      showLoader: 0,
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },

  methods: {
    payment(plan) {
      // Emit event to open PaymentModal11 with package info
      this.$root.$emit("openPaymentModal", {
        title: plan.name,
        amount: plan.amount,
        description: plan.description,
        transactions: plan.transactions,
        perTransaction: plan.perTransaction
      });
    },
    // openPaymentModal() {
    //   this.showAddPaymentModal = true;
    // },
    deleteSubscription(id) {
      const subId = id;
      axios
        .delete(`${process.env.VUE_APP_API_URL}deleteSubscriptionPlan/${subId}`)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.subscriptionPlans.forEach((item, index) => {
              if (item.id === subId) {
                this.subscriptionPlans.splice(index, 1);
                this.$swal("Subscription Deleted!", "Subcription Plan deleted Sucessfully.", "success");

              }
            });
          }
        })
        .catch((error) => {
          console.error(error);
          alert("Failed to delete subscription plan.");
        });
    },
    createSubscriptionsPage() {
      this.$router.push("/createSubscriptions");
    },
    ...mapActions("auth", ["sendVerifyResendRequest"]),
    gettingValueChanged(value) {
      this.parentMessage = value;
    },

    fetchSubscriptionPlans() {
      this.showLoader = 1;
      axios
        .get(`${process.env.VUE_APP_API_URL}getAllSubscriptionPlans`)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.subscriptionPlans = response.data.data;
            this.showLoader = 0;
          }
        })
        .catch((error) => {
          console.error(error);
          alert("Failed to get subscription plan.");
          this.showLoader = 0;
        });
    },
  },
  watch: {},
  mounted() {

    //   if(this.user.user.role_id === 1){
    //   this.isAdmin=true
    //   this.fetchSubscriptionPlans();
    //   }
    //  if (localStorage.getItem("permissions") == 2) {
    //       this.User= true;
    //       this.fetchSubscriptionPlans();
    //   }
    this.$root.$on("openPaymentModal", () => {
      // this.openPaymentModal();
    });
    if (localStorage.getItem("permissions") == 2) {
      this.User = true;
      this.fetchSubscriptionPlans();
    } else if (localStorage.getItem("permissions") == 1) {
      this.isAdmin = true;
      this.fetchSubscriptionPlans();
    }


  },
  created() { },
};
</script>

<style scoped>
.Add {
  background-color: rgb(5, 66, 85);
  color: white;
  width: 150px;
  height: 40px;
  text-align: center;
  border-radius: 4px;
}

.button {
  color: white;
}

.card-pricing {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 15px;
  &:hover {
    box-shadow: 0px 2px 4px rgb(5, 66, 85);
  }
}

.card-pricing .list-unstyled li {
  padding: .5rem 0;
  color: #6c757d;
}
</style>
