var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('button',{ref:"openAddPaymentModal",staticClass:"d-none",attrs:{"data-toggle":"modal","data-target":"#AddPaymentModal"}}),_c('div',{staticClass:"modal fade",attrs:{"id":"AddPaymentModal","tabindex":"-1","aria-labelledby":"exampleModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered modal-dialog-scrollable"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h6',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel"}},[_vm._v(" "+_vm._s(_vm.package_title)+" - $"+_vm._s(_vm.package_amount)+" ")]),_c('button',{ref:"closeModalAddCategory",staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('form',[_c('div',{staticClass:"registry-sign-up-form-content"},[_c('div',{staticClass:"company-info-wrapper"},[_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 p-0"},[_c('p',{staticClass:"price text-capitalize mb-2"},[_vm._v(" Transactions: "+_vm._s(_vm.package_transactions)+" - Per Transaction: $"+_vm._s(_vm.package_perTransaction)+" ")])]),_c('div',{staticClass:"col-12 p-0"},[_c('div',{staticClass:"form-group"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.name.$model),expression:"$v.details.name.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                            'is-invalid': _vm.$v.details.name.$error,
                            'is-valid': !_vm.$v.details.name.$invalid,
                          },attrs:{"type":"text","id":"full_name","placeholder":"Enter Full Name","autocomplete":"off"},domProps:{"value":(_vm.$v.details.name.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.name, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.details.name.required)?_c('span',[_vm._v("Fullname is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 p-0"},[_c('div',{staticClass:"form-group"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.email.$model),expression:"$v.details.email.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                            'is-invalid': _vm.$v.details.email.$error,
                            'is-valid': !_vm.$v.details.email.$invalid,
                          },attrs:{"type":"email","id":"email","placeholder":"Enter Email","autocomplete":"off"},domProps:{"value":(_vm.$v.details.email.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.email, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.details.email.required)?_c('span',[_vm._v("Email is required")]):_vm._e(),(!_vm.$v.details.email.email)?_c('span',[_vm._v("Email must be valid ")]):_vm._e()])])]),_c('div',{staticClass:"col-12 p-0"},[_c('section',{staticClass:"row payment-form"},[(_vm.stripeValidationError != null)?_c('div',{staticClass:"error red center-align white-text"},[_vm._v(" "+_vm._s(_vm.stripeValidationError)+" ")]):_c('div'),_vm._m(2),_vm._m(3),_vm._m(4)])])])])])]),_c('button',{staticClass:"custom-btn2 float-right",attrs:{"type":"button","disabled":_vm.isLoadingArray[0]},on:{"click":function($event){return _vm.createPayment(0)}}},[(_vm.isLoadingArray[0])?_c('div',{staticClass:"lds-dual-ring"}):_c('span',[_vm._v("Create Payment")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"customer_name"}},[_vm._v("Full Name "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"contact_person"}},[_vm._v("Email "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 card-element form-group p-0"},[_c('label',[_vm._v("Card Number")]),_c('div',{staticClass:"input-value",attrs:{"id":"card-number-element"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-lg-6 card-element form-group p-0"},[_c('label',[_vm._v("Expiry Date")]),_c('div',{attrs:{"id":"card-expiry-element"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-lg-6 card-element form-group p-0 pl-lg-2"},[_c('label',[_vm._v("CVC")]),_c('div',{attrs:{"id":"card-cvc-element"}})])
}]

export { render, staticRenderFns }