<template>
  <div class="app-page">
    <Navbar />
    <div class="main-wrapper">
      <LeftMenu />
      <div class="registry-wrapper p-0 bg-transparent overflow-hidden">
        <div class="filter-wrapper">
          <RefundFilters  @clearFilter="handleClearFilter" @showChange="gettingValueChanged" />
        </div>
        <div class="home-registry-wrapper">
          <RefundTop :count="count" :searched="filter.search" />
          <div
            v-if="showLoader == 1"
            class="lds-dual-ring-registry"
            id="loading"
          ></div>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade"
              id="registry-table"
              v-bind:class="{ active: tabindex == 0, show: tabindex == 0 }"
              role="tabpanel"
              aria-labelledby="registry-table-tab"
            >
              
              <RefundTable :message="parentMessage" :refundOrder="refundOrder" :showLoader="showLoader" />
            </div>
          </div>
          <RefundBottom :page="pager" :count="count" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import LeftMenu from "../components/leftmenu/LeftMenu.vue";
import RefundTop from "../components/refunds/RefundTop.vue";
import RefundTable from "../components/refunds/RefundTable.vue";
import RefundBottom from "../components/refunds/RefundBottom.vue";
import Navbar from "../components/header/Navbar.vue";
import RefundFilters from "../components/refunds/RefundFilter.vue";

export default {
  name: "Home",

  components: {
    Navbar,
    LeftMenu,
    RefundTop,
    RefundTable,
    RefundBottom,
    RefundFilters,
  },

  data: function() {
    return {
      parentMessage: false,
      errorMessage: null,
      refundOrder: [],
      showLoader: 0,
      page: 1,
      pager: {},
      perPage: 3,
      pages: [],
      success: null,
      error: null,
      count: 0,
      tabindex: 0,
      images: [
        "https://i.wifegeek.com/200426/f9459c52.jpg",
        "https://i.wifegeek.com/200426/5ce1e1c7.jpg",
        "https://i.wifegeek.com/200426/5fa51df3.jpg",
        "https://i.wifegeek.com/200426/663181fe.jpg",
        "https://i.wifegeek.com/200426/2d110780.jpg",
        "https://i.wifegeek.com/200426/e73cd3fa.jpg",
        "https://i.wifegeek.com/200426/15160d6e.jpg",
        "https://i.wifegeek.com/200426/d0c881ae.jpg",
        "https://i.wifegeek.com/200426/a154fc3d.jpg",
        "https://i.wifegeek.com/200426/71d3aa60.jpg",
        "https://i.wifegeek.com/200426/d17ce9a0.jpg",
        "https://i.wifegeek.com/200426/7c4deca9.jpg",
        "https://i.wifegeek.com/200426/64672676.jpg",
        "https://i.wifegeek.com/200426/de6ab9c6.jpg",
        "https://i.wifegeek.com/200426/d8bcb6a7.jpg",
        "https://i.wifegeek.com/200426/4085d03b.jpg",
        "https://i.wifegeek.com/200426/177ef44c.jpg",
        "https://i.wifegeek.com/200426/d74d9040.jpg",
        "https://i.wifegeek.com/200426/81e24a47.jpg",
        "https://i.wifegeek.com/200426/43e2e8bb.jpg",
      ],
      filter: {},
      pageSize: "15",
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },

  methods: {
    ...mapActions("auth", ["sendVerifyResendRequest"]),
    gettingValueChanged(value) {
      this.parentMessage = value;
    },
    handleClearFilter(clearedFilter) {
      this.filter = clearedFilter; // Update filter with cleared filter object
      this.getDataRegistry(); // Fetch data based on cleared filters
    },
    getDataRegistry() {
      this.showLoader = 1;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}getAllRefunds?page=${this.page}&size=${
            this.pageSize ? this.pageSize : ""}&Customer_id=${
            this.filter.customer_id ? this.filter.customer_id : ""
          }&order_id=${
            this.filter.order_id ? this.filter.order_id : ""
          }&refund_id=${
            this.filter.refund_id ? this.filter.refund_id : ""
          }&Risk_level=${
            this.filter.risk_level ? this.filter.risk_level : ""
          }&search=${
            this.filter.search ? this.filter.search : ""
          }&email=${
            this.filter.email ? this.filter.email : ""
          }&phone=${
            this.filter.phone ? this.filter.phone : ""
          }`,
          {}
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.refundOrder = response.data.data.listing;
            this.pager = response.data.data;
            this.count = response.data.data.totalItems;
            this.showLoader = 0;
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
          }
        });
    },
  },
  watch: {
    "$route.query.page": {
      
        
          
      immediate: true,
      handler(newPage) {
        newPage = parseInt(newPage) || 1;
        if (
          newPage !== this.page
        ) {
          this.page = newPage;
          this.showLoader = 1;
          axios
          .get(
          `${process.env.VUE_APP_API_URL}getAllRefunds?page=${this.page}&size=${
            this.pageSize ? this.pageSize : ""}&Customer_id=${
            this.filter.customer_id ? this.filter.customer_id : ""
          }&order_id=${
            this.filter.order_id ? this.filter.order_id : ""
          }&refund_id=${
            this.filter.RefundID ? this.filter.RefundID : ""
          }&Risk_level=${
            this.filter.risk_level ? this.filter.risk_level : ""
          }&search=${
            this.filter.search ? this.filter.search : ""
          }&email=${
            this.filter.email ? this.filter.email : ""
          }&phone=${
            this.filter.phone ? this.filter.phone : ""
          }`,
          {}
        )
            .then((response) => {
              if (response.data.statusCode == 200) {
                this.refundOrder = response.data.data.listing;
                this.pager = response.data.data;
                this.count = response.data.data.totalItems;
                this.showLoader = 0;
              } else {
                this.errorMessage = response.data.message;
                this.showLoader = 0;
              }
            });
        }
      },
    },
  },
  mounted() {
    this.getDataRegistry();
    this.$root.$on("filterOrders", (data) => {
      this.filter = data;
      this.getDataRegistry();
    });
    
    this.$root.$on("searchForOrders", (data) => {
      this.filter = data;
      this.getDataRegistry();
    });
    this.$root.$on("viewHandle", (data) => {
      this.tabindex = data;
    });
  },
  created() {},
};
</script>
