<template>
  <div class="table-container" v-bind:class="classObject">
    <table class="table table-md-responsive"
     v-if="customerList.length > 0">
      <!-- v-if="showLoader == 0" -->

      <thead>
        <tr>
          <th>CustomerID</th>
          <th class="text-center">Name</th>
          <th class="text-center">Phone</th>
          <!-- <th class="text-center">
            State
          </th> -->
          <th class="text-center">
            Email
          </th>
          <th class="text-center">
            Created At
          </th>
          
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in customerList" :key="index">
          <td>
            <div class="company-details">
              <h6 class="company-name">{{ item.id }}</h6>
            </div>
          </td>
          <td class="text-center">{{ item.first_name }} {{ item.last_name }}</td>
          <td class="text-center">{{ item.phone }}</td>
          <!-- <td class="text-center">{{ item.state}}</td> -->
          <td class="text-center">{{ item.email }}</td>
          <td class="text-center">{{ item.created_at | moment("MMM D, YYYY")  }}</td>
        </tr>
      </tbody>
    </table>
    <div v-if="showLoader == 0 && customerList.length == 0" class="h-75 d-flex flex-column justify-content-center">
        <p class="no-registry">No customer found.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomersTable",

  data() {
    return {};
  },
  props: {
    customerList: {
      type: Array,
    },
    message: Boolean,
    showLoader: Number,
  },
  methods: {},
  computed: {
    classObject: function () {
      return {
        'reg-table': this.message,
        'reg2-table': !this.message,
      }
    },
  },
  mounted() {},
};
</script>
