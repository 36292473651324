<template>
  <div class="table-container" v-bind:class="classObject">
    <table class="table table-md-responsive"
      v-if="refundOrder.length > 0"
      >
      <!-- v-if="showLoader == 0" -->

      <thead>
        <tr>
          <th>
            OrderID
          </th>
          <th>CustomerID</th>
          <th class="text-center">Phone</th>

          <th class="text-center">
            Email
          </th>
          <th class="text-center">
            RefundID
          </th>
          <th class="text-center">Risk Level</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in refundOrder" :key="index">
          <td>
            <div class="company-details">
              <h6 class="company-name">{{ item.OrderID }}</h6>
            </div>
          </td>
          <td class="text-center" >{{ item.CustomerID }}</td>
          <td class="text-center" >{{ item.phone }}</td>
          <td class="text-center" >{{ item.email }}</td>
          <td class="text-center" >{{ item.RefundID }}</td>
          <td class="text-center" >{{ item.Risk_level }}</td>
        </tr>
      </tbody>
    </table>
    <div v-if="showLoader == 0 && refundOrder.length == 0" class="h-75 d-flex flex-column justify-content-center">
        <p class="no-registry">No refund order found.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderTable",

  data() {
    return {};
  },
  props: {
    refundOrder: {
      type: Array,
    },
    message: Boolean,
    showLoader: Number,

  },
  methods: {},
  computed: {
    classObject: function () {
      return {
        'reg-table': this.message,
        'reg2-table': !this.message,
      }
    },
  },
  mounted() { },
};
</script>