<template> 
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#AddPaymentModal"
      ref="openAddPaymentModal"
    ></button>
    <div
      class="modal fade"
      id="AddPaymentModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="exampleModalLabel">
              {{ package_title }} - ${{ package_amount }}
            </h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalAddCategory"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="registry-sign-up-form-content">
                <div class="company-info-wrapper">
                  <div class="container-fluid p-0">
                    <div class="row">
                      <div class="col-12 p-0">
                        <p class="price text-capitalize mb-2">
                           Transactions: {{ package_transactions }} - Per Transaction: ${{ package_perTransaction }}
                        </p>
                      </div>
                      <div class="col-12 p-0">
                        <div class="form-group">
                          <label for="customer_name"
                            >Full Name <span class="astrick">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="full_name"
                            placeholder="Enter Full Name"
                            autocomplete="off"
                            v-model.trim="$v.details.name.$model"
                            :class="{
                              'is-invalid': $v.details.name.$error,
                              'is-valid': !$v.details.name.$invalid,
                            }"
                          />
                          <div class="invalid-feedback">
                            <span v-if="!$v.details.name.required"
                              >Fullname is required</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-12 p-0">
                        <div class="form-group">
                          <label for="contact_person"
                            >Email <span class="astrick">*</span>
                          </label>
                          <input
                            type="email"
                            v-model.trim="$v.details.email.$model"
                            class="form-control"
                            id="email"
                            placeholder="Enter Email"
                            :class="{
                              'is-invalid': $v.details.email.$error,
                              'is-valid': !$v.details.email.$invalid,
                            }"
                            autocomplete="off"
                          />
                          <div class="invalid-feedback">
                            <span v-if="!$v.details.email.required"
                              >Email is required</span
                            >
                            <span v-if="!$v.details.email.email"
                              >Email must be valid
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 p-0">
                        <section class="row payment-form">
                          <div
                            v-if="stripeValidationError != null"
                            class="error red center-align white-text"
                          >
                            {{ stripeValidationError }}
                          </div>
                          <div v-else></div>

                          <div class="col-12 card-element form-group p-0">
                            <label>Card Number</label>
                            <div
                              id="card-number-element"
                              class="input-value"
                            ></div>
                          </div>
                          <div
                            class="col-12 col-lg-6 card-element form-group p-0"
                          >
                            <label>Expiry Date</label>
                            <div id="card-expiry-element"></div>
                          </div>

                          <div
                            class="col-12 col-lg-6 card-element form-group p-0 pl-lg-2"
                          >
                            <label>CVC</label>
                            <div id="card-cvc-element"></div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                class="custom-btn2 float-right"
                @click="createPayment(0)"
                :disabled="isLoadingArray[0]"
              >
                <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                <span v-else>Create Payment</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://js.stripe.com/v3/"></script>
<script>
import { required, email } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "PaymentModal",
  data() {
    return {
      details: {
        name: "",
        email: "",
      },
      package_title: "",
      package_amount: "",
      package_transactions: "",
      package_perTransaction: "",
      subscriptionPlanId: null,
      package_duration: "",
      stripe: null,
      cardNumberElement: null,
      cardExpiryElement: null,
      cardCVCElement: null,
      isLoadingArray: [],
      stripeValidationError: null,
      userId: "",
    };
  },
  validations: {
    details: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    createPayment(index) {
      var formData = new FormData();
      formData.append("payment_amount", this.payment_amount);

      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);

      this.stripe.createToken(this.cardNumberElement).then((result) => {
        if (result.error) {
          this.stripeValidationError = result.error.message;
        } else {
          const stripeObject = {
            userId: localStorage.getItem("loginUserId"),
            amount: this.package_amount,
            source: result.token,
            description: this.package_title + ' - ' + this.package_duration,
          };
          this.saveData(stripeObject);
        }
        this.$set(this.isLoadingArray, index, false);
      });
    },
    createAndMountFormElements() {
      console.log("#card-number-element")
      var elements = this.stripe.elements();
      this.cardNumberElement = elements.create("cardNumber");
      this.cardNumberElement.mount("#card-number-element");

      this.cardExpiryElement = elements.create("cardExpiry");
      this.cardExpiryElement.mount("#card-expiry-element");

      this.cardCvcElement = elements.create("cardCvc");
      this.cardCvcElement.mount("#card-cvc-element");
    },
    saveData(stripeObject) {
      axios
        .post(process.env.VUE_APP_API_URL + "payment/sendStripeToken", stripeObject)
        .then((response) => {
          // if (response.data.statusCode === 200) {
            this.$swal("Subscription Activated!", "Your subscription has been activated successfully.", "success");
            this.$root.$emit("reNewSubs");
            
            const elem = this.$refs.closeModalAddCategory;
            if (elem) {
              elem.click();
            }
          // } else {
          //   alert('Something went wrong');
          //   console.log(response.data.message);
          // }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            alert('Something went wrong');
          }
        });
    },
  },
  mounted() {
    this.$root.$on("openPaymentModal", (packageDetails) => {
      const elem = this.$refs.openAddPaymentModal;
      if (elem) {
        elem.click();
      }
      this.package_title = packageDetails.title;
      this.package_amount = packageDetails.amount;
      this.package_transactions = packageDetails.transactions;
      this.package_perTransaction = packageDetails.perTransaction;
    });

    this.stripe = Stripe(this.$stripePublicKey);
    this.createAndMountFormElements();
  },
};
</script>

<style scoped>
label,
input,
input::placeholder,
input.form-control:focus {
  color: #383838;
}

input {
  padding-left: 10px;
  font-weight: 400;
}

#card-number-element,
#card-expiry-element,
#card-cvc-element {
  background: white;
  padding: 10px;
  border: 1px solid #ececec;
  border-radius: 5px;
}
</style>
